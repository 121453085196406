import React from "react"

import Map from "./Map";
import FooterText from "./FooterText";

const Footer = () => (
  <footer className="grid-item__full footer">
    <Map />
    <FooterText />
  </footer>
)

export default Footer