import React from "react"
import { Link } from "gatsby"

import { navLinks } from "../../data/navLinks"
import Button from "../Button"

const FooterText = () => (
  <div className="footer__grid">

    <div className="footer__column">
      <h3 className="heading footer__heading">Contact</h3>

      <div className="footer__row">
        <div>2611 151 Avenue Northwest</div>
        <div>Edmonton, AB — T5Y 2M4</div>
      </div>

      <div className="footer__row">
        <div><b>TEL:</b> +1 (780) 476-2591</div>
        <div><b>FAX:</b> +1 (780) 476-4998</div>
      </div>

      <div className="footer__row">
        <a className="footer__link" href="mailto:fraserchildcare@yahoo.com">fraserchildcare@yahoo.com</a>
      </div>
    </div>

    <div className="footer__column">
      <h3 className="heading footer__heading">Hours of Operation</h3>

      <div className="footer__row">
        <div>Monday — Friday</div>
        <div>6:30 a.m. — 5:45 p.m</div>
      </div>

      <div className="footer__row">
        <Button color="blue" linkUrl="/contact">Get In Touch</Button>
      </div>
    </div>

    <div className="footer__column footer__column--full">
      <ul className="footer__nav">
      {
        navLinks.map(link => (
          <li>
            <Link activeClassName="is-active" className="footer__nav-link" to={link.path}>{link.name}</Link>
          </li>   
        ))
      }
      </ul>
    </div>

    <div className="footer__column footer__column--full text--centered">
      <Link to="/privacy" className="footer__link">Privacy Policy</Link>
    </div>
  </div>
)

export default FooterText