import React from "react"

import Banner from "./Banner"
import Navbar from "./Navbar"

const Header = () => (
  <header className="header">
    <Banner />
    <Navbar />
  </header>
)

export default Header