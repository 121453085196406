/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Head from "../components/navigation/Head"
import Header from "../components/navigation/Header"
import Footer from "../components/navigation/Footer"

const Layout = ({ children }) => {

  return (
    <>
      <Head />
      <Header />
      <main className="site">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
