import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Button = ({ children, color, linkUrl, spaceAbove }) => {
  let buttonClasses = "button button--" + color
  if (spaceAbove) buttonClasses += " button--space-above"

  const button = (
    <button className={buttonClasses}>
      {children}
    </button>
  );

  if (linkUrl) return (
    <Link className="button--link" to={linkUrl}>
      {button}
    </Link>
  );

  return button;
}

Button.propTypes = {
  color: PropTypes.string,
  linkUrl: PropTypes.string,
  spaceAbove: PropTypes.bool
}

export default Button