import React from "react"

import earthImage from "../../images/Earth.png"
import circlesImage from "../../images/Circles.png"

const Banner = () => (
  <div className="banner" style={{ backgroundImage: `url(${circlesImage})` }}>
    <div className="banner__content">
      <div className="banner__logo">
        <img src={earthImage} alt="Logo" />
      </div>
      <h1 className="banner__text">
        Fraser Community Child Care Center & Out of School Care
      </h1>
    </div>
  </div>
)

export default Banner
