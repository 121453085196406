import React from "react"
import { Link } from "gatsby"

import { navLinks } from "../../data/navLinks"

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar__links">
      {
        navLinks.map(link => (
          <li className="navbar__link">
            <Link activeClassName="is-active" className="navbar__a" to={link.path}>
              <div className="navbar__link-text">{link.name}</div>
            </Link>
          </li>
        ))
      }
      </ul>
    </nav>
  )
}

export default Navbar
